@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Tilt+Warp&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-top-12 {
  top: -3rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-4 {
  top: 1rem;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-12 {
  height: 3rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-8 {
  height: 2rem;
}

.h-screen {
  height: 100vh;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.min-h-\[430px\] {
  min-height: 430px;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-8 {
  width: 2rem;
}

.w-\[980px\] {
  width: 980px;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-\[1430px\] {
  max-width: 1430px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[845px\] {
  max-width: 845px;
}

.max-w-\[860px\] {
  max-width: 860px;
}

.max-w-\[980px\] {
  max-width: 980px;
}

.max-w-full {
  max-width: 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-36 {
  --tw-translate-y: 9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-72 {
  --tw-translate-y: 18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.appearance-none {
  appearance: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.bg-\[\#101754\] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 23 84 / var(--tw-bg-opacity));
}

.bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-\[\#f1f1f1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(48 86 211 / var(--tw-bg-opacity));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(42 78 197 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 150 / var(--tw-bg-opacity));
}

.bg-secondary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(12 156 120 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\'\/assets\/images\/banner\.webp\'\)\] {
  background-image: url("banner.53fb5e05.webp");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-primary {
  --tw-gradient-from: #3056d3;
  --tw-gradient-to: #3056d300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#e4e4e4\] {
  --tw-text-opacity: 1;
  color: rgb(228 228 228 / var(--tw-text-opacity));
}

.text-body-color {
  --tw-text-opacity: 1;
  color: rgb(99 115 129 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(9 14 52 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(48 86 211 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder-\[\#ACB6BE\]::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(172 182 190 / var(--tw-placeholder-opacity));
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body {
  font-family: Tilt Warp, cursive;
  overflow: hidden;
}

.text-3xl {
  font-size: 2.3rem;
  line-height: 3.2rem;
}

.text-4xl {
  font-size: 3rem;
  line-height: 3.5rem;
}

.text-5xl {
  font-size: 3.5rem;
  line-height: 4rem;
}

.text-6xl {
  font-size: 4rem;
  line-height: 4.5rem;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 5rem;
}

.home {
  height: 100vh;
  scroll-behavior: smooth;
  overflow: auto;
  transform: translateZ(0);
}

.home > .section {
  width: 100vw;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  transform: translateZ(0);
}

.margin-auto {
  margin: 0 auto !important;
}

@media screen and (min-width: 821px) {
  .home {
    scroll-snap-type: y mandatory;
  }

  .home > .section {
    height: 100vh;
    width: 100vw;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}

::-webkit-scrollbar, ::-moz-scrollbar, ::-o-scrollbar {
  width: 0;
  height: 50vh;
}

::-webkit-scrollbar-thumb, ::-moz-scrollbar-thumb, ::-o-scrollbar-thumb {
  background: none;
}

::-webkit-scrollbar-track, ::-moz-scrollbar-track, ::-o-scrollbar-track {
  background: none;
}

.normal-txt {
  font-family: Open Sans, sans-serif;
}

.wrap-txt {
  font-family: Tilt Warp, cursive;
}

.sticky {
  -webkit-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px #0000001a;
}

.loader {
  width: 48px;
  height: 48px;
  color: #3056d3;
  box-sizing: border-box;
  margin: 15px auto;
  animation: 1s linear infinite rotation;
  display: block;
  position: relative;
}

.loader:after, .loader:before {
  content: "";
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background-color: #3056d3;
  border-radius: 50%;
  animation: 1s ease-in-out infinite animloader;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(.5)translate(0);
}

.loader:before {
  background-color: #00adef;
  transform: scale(.5)translate(-48px, -48px);
}

.loader.loader-white {
  color: #fff;
  width: 28px;
  height: 10px;
  margin: 8px auto;
  padding: 5px;
}

.loader.loader-white:after, .loader.loader-white:before {
  width: 18px;
  height: 18px;
  background-color: #fff;
}

.loader.loader-white:before {
  transform: scale(.5)translate(-36px, -36px);
}

.local-url-item {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  padding: 16px;
}

button:hover {
  transform: scale(.98) !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  50% {
    transform: scale(.75)translate(-50%, -50%);
  }
}

.shake-anim {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: .82s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#f0f0f0\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.hover\:bg-primary-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(42 78 197 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 156 120 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(48 86 211 / var(--tw-text-opacity));
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-blue-500\/50:hover {
  --tw-shadow-color: #3b82f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(48 86 211 / var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:shadow-none:focus-visible {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 540px) {
  .sm\:flex {
    display: flex;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 720px) {
  .md\:-top-24 {
    top: -6rem;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-10 {
    bottom: 2.5rem;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:bottom-20 {
    bottom: 5rem;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-16 {
    left: 4rem;
  }

  .md\:right-6 {
    right: 1.5rem;
  }

  .md\:right-8 {
    right: 2rem;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:block {
    display: block;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 960px) {
  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1140px) {
  .xl\:bottom-1\/2 {
    bottom: 50%;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (min-width: 1320px) {
  .\32 xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=404.d2fcd632.css.map */
